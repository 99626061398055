export default {
  CONTENIDOS_RECEIVED: "CONTENIDOS_RECEIVED",
  MOVE_CAROUSELL: "MOVE_CAROUSELL",
  TOGGLE_MOBILE_TOP_MENU: "TOGGLE_MOBILE_TOP_MENU",
  MOVE_TO_SECTION: "MOVE_TO_SECTION",
  CIERRA_COOKIES_AVISO: "CIERRA_COOKIES_AVISO",
  INICIO_RECEIVED: "INICIO_RECEIVED",
  EQUIPO_RECEIVED: "EQUIPO_RECEIVED",
  INSTALACIONES_RECEIVED: "INSTALACIONES_RECEIVED",
  TARIFAS_RECEIVED: "TARIFAS_RECEIVED",
  SERVICIOS_RECEIVED: "SERVICIOS_RECEIVED",
  TECNICAS_RECEIVED: "TECNICAS_RECEIVED",
  CONTACTO_RECEIVED: "CONTACTO_RECEIVED"
};
